import React from "react";
import SnipcartButton from "../../../reusable/SnipcartButton/index.js";
import { Styling } from "./styled.js";

export default function LandingPageSnipcartButton({
  landingPage,
  page,
  path,
  darkLightMode,
  index,
  category,
}) {
  return (
    <Styling darkLightMode={darkLightMode}>
      <SnipcartButton
        // className={`snipcart-add-item`}
        categories={category}
        id={`snipcart-${page?.acfLandingPage.product.databaseId}`}
        meta={page?.acfLandingPage.product.databaseId}
        price={page?.acfLandingPage.product.courseBuilder.price}
        url={page?.acfLandingPage.customurl}
        description={`Toolroom Academy Course: ${page?.title}`}
        image={
          page.acfLandingPage.product.courseBuilder.courseThumbnailImage
            .publicUrl
        }
        name={`${page?.title}`}
        buttonText={`${landingPage.buttonText} £${page?.acfLandingPage.product.courseBuilder.price}`}
      />
    </Styling>
  );
}
