import React from "react";
import { Styling } from "./styled.js";
import parse from "html-react-parser";
import { GatsbyImage } from "gatsby-plugin-image";

export default function LandingHeadingTextIconText({
  item,
  darkLightMode,
  index,
}) {
  //console.log(item)
  return (
    <Styling id={`section_${index + 1}`} darkLightMode={darkLightMode}>
      <div className="main_title">{item.heading}</div>
      <div className="text">{parse(item.text)}</div>
      <ul>
        {item.iconsText.map((iconText) => {
          return (
            <li>
              <GatsbyImage
                image={iconText.icon.localFile.childImageSharp.gatsbyImageData}
              />
              {parse(iconText.text)}
            </li>
          );
        })}
      </ul>
    </Styling>
  );
}
