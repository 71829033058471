import React, { useRef, useState } from "react";
import { Styling } from "./styled.js";
import Arrow from "../../../../images/arrow_down.inline.svg";
import PlayIcon from "../../../../images/play.inline.svg";

export default function ProductKeyFeatures({
  context,
  video,
  features,
  product,
  title,
  databaseId,
  snipcartGuid,
  snipcartButtonText,
  path,
  page,
  removeTopMargin,
  darkLightMode,
  index,
  category,
}) {
  let accordion;
  let description;
  let briefProductDescription;
  let price;
  let images;
  let salePrice;

  //console.log("🔥", category);
  if (product) {
    accordion = product.accordion;
    description = product.description;
    briefProductDescription = product.briefProductDescription;
    price = product.price;
    images = product.images;
    salePrice = product.salePrice;
  }

  const videoRef = useRef();

  const returnPrice = () => {
    if (salePrice) {
      return salePrice.toFixed(2);
    }

    if (price) {
      return price.toFixed(2);
    }

    if (page) {
      return page.acfLandingPage.price;
    }
  };

  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayVideo = () => {
    const video = videoRef.current;
    if (isPlaying === false) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  return (
    <Styling
      id={`section_${index + 1}`}
      darkLightMode={darkLightMode}
      removeTopMargin={removeTopMargin}
    >
      {/* SAMPLE PACK PAGE */}
      <div className={`features-wrapper ${video ? "" : "full-width"}`}>
        <div className="title">
          Key features <Arrow />
        </div>
        <ul className="features">
          {features.map((feature, index) => {
            return <li key={`key_feature_${index}`}>{feature.feature}</li>;
          })}
        </ul>

        <button
          class="snipcart-add-item capsule_button white"
          data-item-id={`snipcart-${index + 1}-${databaseId}`}
          data-item-price={returnPrice()}
          data-item-url={path ? path : "/shop" + context.link}
          data-item-description={
            briefProductDescription ? briefProductDescription : ""
          }
          data-item-categories={category}
          data-item-metadata={`{"trackQuantities": false, "isPlugin": false}`}
          data-item-image={
            page?.acfLandingPage?.productImage?.publicUrl ||
            images[0]?.image?.localFile?.publicURL
          }
          data-item-name={title || page.title}
          data-item-quantity={1}
          data-item-max-quantity={1}
          data-item-file-guid={snipcartGuid ? snipcartGuid : undefined}
        >
          {snipcartButtonText
            ? snipcartButtonText
            : `Add to cart - £${returnPrice()}`}
        </button>
      </div>
      {video ? (
        <div
          className={
            isPlaying ? `video-wrapper playing` : `video-wrapper paused`
          }
          onClick={handlePlayVideo}
        >
          <PlayIcon />
          <video playsInline ref={videoRef} src={video}></video>
        </div>
      ) : (
        ""
      )}
    </Styling>
  );
}
