import styled from 'styled-components'

const Styling = styled.div`
width:100%;
background:${(props) => props.darkLightMode === 'dark' || props.darkLightMode === undefined ? 'var(--black)' : 'var(--white)'};
color:${(props) => props.darkLightMode === 'dark' || props.darkLightMode === undefined ? 'var(--white)' : 'var(--black)'};
position: relative;
padding: 4rem;
text-align:center;

.capsule_button.border_hover{
border:1px solid ${(props) => props.darkLightMode === 'light' || props.darkLightMode === undefined ? 'var(--black)' : 'var(--white)'} !important;
  color:${(props) => props.darkLightMode === 'light' || props.darkLightMode === undefined ? 'var(--white)' : 'var(--black)'};
  background:${(props) => props.darkLightMode === 'light' || props.darkLightMode === undefined ? 'var(--black)' : 'var(--white)'};

      &:hover{
      /* border:1px solid var(--black) !important; */
      background:${(props) => props.darkLightMode === 'light' || props.darkLightMode === undefined ? 'var(--white)' : 'var(--black)'};
      color:${(props) => props.darkLightMode === 'light' || props.darkLightMode === undefined ? 'var(--black)' : 'var(--white)'};
      border:1px solid ${(props) => props.darkLightMode === 'light' || props.darkLightMode === undefined ? 'var(--black)' : 'var(--white)'} !important;
      /* border-color:${(props) => props.darkLightMode === 'light' || props.darkLightMode === undefined ? 'var(--black)' : 'var(--white)'} !important; */
      }

  }

`

export { Styling }