import React from "react";
import { Styling } from "./styled.js";
import parse from "html-react-parser";
import { Link } from "gatsby";
import Arrow from "../../../../images/arrow_down_right.inline.svg";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function LandingTextImageBlock({
  alignment,
  heading,
  text,
  button,
  image,
  darkLightMode,
  index,
  customUrl,
}) {
  return (
    <Styling
      id={`section_${index + 1}`}
      className={`${alignment} ${darkLightMode}`}
      darkLightMode={darkLightMode}
    >
      <div className="left">
        <div className="title">
          <Arrow /> {parse(heading)}
        </div>
        <div className="text">{parse(text)}</div>
        {button.buttonType === "link" ? (
          <a
            href={button.buttonLink}
            className="capsule_button black border_white"
          >
            {button.buttonText}
          </a>
        ) : (
          ""
        )}
        {button.buttonType === "scrollTo" ? (
          <a
            href={button.scrollToTargetId}
            className="capsule_button black border_white"
          >
            {button.buttonText}
          </a>
        ) : (
          ""
        )}
      </div>

      <div className="right">
        <GatsbyImage
          image={getImage(image.localFile)}
          className="image"
          alt={"test"}
        />
      </div>
    </Styling>
  );
}
