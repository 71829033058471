import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/global/seo";
import ProductionProgrammeIntro from "../components/page-components/ProductionProgrammes/ProductionProgrammesIntro";
import LandingHeadingTextIconText from "../components/page-components/LandingPageBuilder/LandingHeadingTextIconText";
import LandingTextImageBlock from "../components/page-components/LandingPageBuilder/LandingTextImageBlock";
import LandingPageSnipcartButton from "../components/page-components/LandingPageBuilder/LandingPageSnipcartButton";
import ProductionProgrammeAbout from "../components/page-components/ProductionProgrammes/ProductionProgrammeAbout";
import ProductionProgrammeChapters from "../components/page-components/ProductionProgrammes/ProductionProgrammeChapters";
import CourseTutorSlider from "../components/reusable/CourseTutorSlider";
import Testimonials from "../components/page-components/Homepage/Testimonials";
import ProductKeyFeatures from "../components/page-components/Products/ProductKeyFeatures";
import Video from "../components/reusable/Video";
import IsThisCourseForMe from "../components/reusable/IsThisCourseForMe";
import WhatYoullLearn from "../components/reusable/WhatYoullLearn";
import LandingPageArchiveLinkButton from "../components/page-components/LandingPageBuilder/LandingPageArchiveLinkButton";
export default function LandingPageBuilder({ data }) {
  const page = data.wpPage;
  return (
    <div>
      <Seo title={data.wpPage.seo.title} description={data.wpPage.seo.metaDesc} />
      {page.acfLandingPage.pageBuilder.map((item, index) => {
        if (item.fieldGroupName === "page_Acflandingpage_PageBuilder_LandingPageIntroBlock") {
          //console.log(page.acfLandingPage.productArchiveUrl);
          return (
            <ProductionProgrammeIntro
              course={undefined}
              video={item.video}
              introText={item.text}
              title={item.heading}
              page={page}
              path={page.acfLandingPage.productUrl}
              snipcart_description={`Toolroom Academy Course: ${page.title}`}
              snipcart_meta={page.acfLandingPage.product.databaseId}
              snipcart_id={page.acfLandingPage.product.databaseId}
              snipcart_price={page.acfLandingPage.product.courseBuilder.price}
              externalUrl={page.acfLandingPage.product.courseBuilder.externalUrl}
              isSoldExternally={page.acfLandingPage.product.courseBuilder.isSoldExternally}
              snipcart_image={
                page.acfLandingPage.product.courseBuilder.courseThumbnailImage.publicUrl
              }
              category={page.acfLandingPage.productCategory}
              darkLightMode={item.darkLightMode}
              index={index}
              buttonType={item.buttonType}
              buttonLink={page.acfLandingPage.productArchiveUrl}
              buttonText={"Learn more"}
            />
          );
        }

        if (
          item.fieldGroupName === "page_Acflandingpage_PageBuilder_LandingPageHeadingTextIconsText"
        ) {
          return (
            <LandingHeadingTextIconText
              item={item}
              darkLightMode={item.darkLightMode}
              index={index}
            />
          );
        }

        if (
          item.fieldGroupName ===
          "page_Acflandingpage_PageBuilder_LandingPageImageHeadingTextButton"
        ) {
          return (
            <LandingTextImageBlock
              alignment={item.alignment}
              heading={item.heading}
              text={item.text}
              button={item.button}
              customUrl={page.acfLandingPage.customurl}
              image={item.image}
              darkLightMode={item.darkLightMode}
              index={index}
            />
          );
        }

        if (
          item.fieldGroupName === "page_Acflandingpage_PageBuilder_LandingPageCourseDescription"
        ) {
          return (
            <ProductionProgrammeAbout
              landingPage={item}
              page={page}
              path={page.acfLandingPage.customurl}
              darkLightMode={item.darkLightMode}
              index={index}
              listtype="ul"
              category={page.acfLandingPage.productCategory}
              externalUrl={page.acfLandingPage.product.courseBuilder.externalUrl}
              isSoldExternally={page.acfLandingPage.product.courseBuilder.isSoldExternally}
            />
          );
        }

        if (item.fieldGroupName === "page_Acflandingpage_PageBuilder_LandingPageChapters") {
          return (
            <ProductionProgrammeChapters
              landingPage={item}
              page={page}
              path={"/" + page.slug}
              darkLightMode={item.darkLightMode}
              index={index}
            />
          );
        }

        if (item.fieldGroupName === "page_Acflandingpage_PageBuilder_LandingPageCourseTutor") {
          return (
            <CourseTutorSlider
              tutors={[item.pickATutor]}
              instructorsHeading={"Course Tutor"}
              darkLightMode={item.darkLightMode}
              index={index}
            />
          );
        }

        if (item.fieldGroupName === "page_Acflandingpage_PageBuilder_LandingPageTestimonials") {
          return (
            <Testimonials
              overrideHeadingSize={true}
              testimonialsData={item.testimonials}
              customHeading1={"What our"}
              customHeading2={"students say"}
              hideReadMore={true}
              showStars={true}
              darkLightMode={item.darkLightMode}
              index={index}
            />
          );
        }

        if (item.fieldGroupName === "page_Acflandingpage_PageBuilder_LandingPageKeyFeaturesVideo") {
          return (
            <ProductKeyFeatures
              removeTopMargin={true}
              snipcartButtonText={item.snipcartButtonText}
              path={process.env.GATSBY_FRONTEND_URL + "/" + page.slug}
              page={page}
              video={item.video}
              features={item.features}
              darkLightMode={item.darkLightMode}
              index={index}
              category={page.acfLandingPage.productCategory}
            />
          );
        }

        if (
          item.fieldGroupName === "page_Acflandingpage_PageBuilder_LandingPageHeadingImageTextCards"
        ) {
          return (
            <WhatYoullLearn
              deliverables={item.imageTextCards}
              heading={item.heading}
              darkLightMode={item.darkLightMode}
              index={index}
            />
          );
        }

        if (
          item.fieldGroupName ===
          "page_Acflandingpage_PageBuilder_LandingPageHeadingSubheadingsTextCards"
        ) {
          return (
            <IsThisCourseForMe
              items={item.subheadingTextCards}
              interactiveProgrammeIsThisCourseForMeHeading={item.heading}
              darkLightMode={item.darkLightMode}
              index={index}
            />
          );
        }

        if (item.fieldGroupName === "page_Acflandingpage_PageBuilder_LandingPageFullWidthVideo") {
          return (
            <Video
              video={item.videoEmbedCode}
              addBottomColor={item.bottomHalfColour !== "oneColor" ? true : false}
              bottomColor={item.bottomHalfColour}
              darkLightMode={item.darkLightMode}
              index={index}
              heading={item.heading}
            />
          );
        }

        if (item.fieldGroupName === "page_Acflandingpage_PageBuilder_LandingPageSnipcartButton") {
          return (
            <LandingPageSnipcartButton
              landingPage={item}
              page={page}
              path={"/" + page.slug}
              darkLightMode={item.darkLightMode}
              index={index}
              category={page.acfLandingPage.productCategory}
            />
          );
        }

        if (
          item.fieldGroupName === "page_Acflandingpage_PageBuilder_LandingPageOndemandarchivebutton"
        ) {
          return (
            <LandingPageArchiveLinkButton
              darkLightMode={item.darkLightMode}
              index={index}
              buttonText={item.buttonText}
              buttonLink={page.acfLandingPage.productArchiveUrl}
            />
          );
        }
      })}
    </div>
  );
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      databaseId
      seo {
        title
        metaDesc
      }

      acfLandingPage {
        productCategory
        productArchiveUrl
        productUrl
        product {
          ... on WpCourse {
            databaseId
            courseBuilder {
              price
              externalUrl
              isSoldExternally
              courseThumbnailImage {
                publicUrl
              }
            }
            customSnipcartGuid {
              customSnipcartGuid
            }
          }
        }

        pageBuilder {
          ... on WpPage_Acflandingpage_PageBuilder_LandingPageSnipcartButton {
            buttonText
            darkLightMode
            fieldGroupName
          }
          ... on WpPage_Acflandingpage_PageBuilder_LandingPageOndemandarchivebutton {
            buttonText
            darkLightMode
            fieldGroupName
          }
          ... on WpPage_Acflandingpage_PageBuilder_LandingPageIntroBlock {
            darkLightMode
            fieldGroupName
            heading
            snipcartButtonText
            buttonType
            text
            video
          }
          ... on WpPage_Acflandingpage_PageBuilder_LandingPageChapters {
            darkLightMode
            fieldGroupName
            heading
            chapters {
              heading
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              text
            }
          }
          ... on WpPage_Acflandingpage_PageBuilder_LandingPageImageHeadingTextButton {
            alignment
            darkLightMode
            heading
            text
            button {
              buttonLink
              buttonText
              buttonType
              scrollToTargetId
            }
            fieldGroupName
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPage_Acflandingpage_PageBuilder_LandingPageCourseDescription {
            darkLightMode
            fieldGroupName
            heading
            snipcartButtonText
            text
            keyFeatures {
              keyFeatures {
                feature
              }
              keyFeaturesHeading
            }
          }
          ... on WpPage_Acflandingpage_PageBuilder_LandingPageHeadingTextIconsText {
            darkLightMode
            fieldGroupName
            heading
            text
            iconsText {
              icon {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              text
            }
          }
          ... on WpPage_Acflandingpage_PageBuilder_LandingPageCourseTutor {
            darkLightMode
            fieldGroupName
            pickATutor {
              ... on WpInstructor {
                title
                instructor {
                  biography
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Acflandingpage_PageBuilder_LandingPageTestimonials {
            darkLightMode
            fieldGroupName
            heading
            testimonials {
              testimonialText
              testimonialImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPage_Acflandingpage_PageBuilder_LandingPageKeyFeaturesVideo {
            darkLightMode
            fieldGroupName
            heading
            video
            features {
              feature
            }
          }
          ... on WpPage_Acflandingpage_PageBuilder_LandingPageHeadingImageTextCards {
            darkLightMode
            fieldGroupName
            heading
            imageTextCards {
              heading
              text
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPage_Acflandingpage_PageBuilder_LandingPageHeadingSubheadingsTextCards {
            darkLightMode
            fieldGroupName
            heading
            subheadingTextCards {
              subheading
              text
            }
          }
          ... on WpPage_Acflandingpage_PageBuilder_LandingPageFullWidthVideo {
            darkLightMode
            heading
            bottomHalfColour
            fieldGroupName
            videoEmbedCode
          }
        }
      }
    }
  }
`;
