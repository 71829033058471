import { Link } from "gatsby";
import React from "react";
import SnipcartButton from "../../../reusable/SnipcartButton/index.js";
import { Styling } from "./styled.js";

export default function LandingPageArchiveLinkButton({
  darkLightMode,
  index,
  buttonText,
  buttonLink,
}) {
  return (
    <Styling darkLightMode={darkLightMode}>
      <Link to={buttonLink} className="capsule_button border_hover">
        {buttonText}
      </Link>
    </Styling>
  );
}
