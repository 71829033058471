import styled from 'styled-components'

const Styling = styled.div`
width:100%;
background:${(props) => props.darkLightMode === 'light' || props.darkLightMode === undefined ? 'var(--white)' : 'var(--black)'};
color:${(props) => props.darkLightMode === 'light' || props.darkLightMode === undefined ? 'var(--black)' : 'var(--white)'};
display: flex;
justify-content: center;
align-items: center;
align-content: center;
flex-wrap: wrap;
padding:4rem 2rem;

    &:last-of-type{
    margin-top:4rem;
    }

    @media only screen and (min-width: 350px) {
    padding:4rem;
    }


    @media only screen and (min-width: 768px) {
        padding:4rem 8rem;
        flex-wrap: nowrap;
    }

    @media only screen and (min-width: 1200px) {
    padding:5rem 10rem;
    }

    &.irtl{
        .left{
        order:1;
        }

        .right{
        order:2;
        }
    }
    &.iltr{
        .left{
            @media only screen and (min-width: 768px) {
            order:2;
            }
        }

        .right{
            @media only screen and (min-width: 768px) {
            order:1;
            margin-left: 0%;
            margin-right: 10%;
            }
        }
    }


    .left{
    width:100%;
    margin-bottom: 4rem;

        @media only screen and (min-width: 400px) {       
        text-align: center;
        }

        @media only screen and (min-width: 768px) {        
        width:50%;
        margin-bottom: 0;
        text-align: left;
        }

        .title{
        font-size:clamp(3rem, 3vw, 9rem);
        width: 100%;
        text-transform: uppercase;
        ${({theme}) => theme.tomato('regular')}  
        letter-spacing: -0.07em;
        display: flex;
        align-items:center;
        margin-bottom: 2rem;

            @media only screen and (min-width: 350px) {
            margin-bottom: 4rem;
            }

            @media only screen and (max-width: 768px) {        
            justify-content: center;
            }

            svg{
            width:clamp(3rem/1.5, 3vw/1.5, 9rem/1.5);
            margin-right: 1rem;

                path{
                fill:${(props) => props.darkLightMode === 'light' || props.darkLightMode === undefined ? 'var(--black)' : 'var(--white)'};
                }
            }

        }

        .text{
        font-size:1.6rem;
        line-height: 2.5rem;
        margin-bottom: 4rem;

            @media only screen and (min-width: 768px) {        
            /* max-width: 80%; */
            }

        }

        p{
        margin-bottom: 1rem;

            &:last-child{
            margin-bottom: 0;
            }

        }
        button,
        a{
            @media only screen and (max-width: 550px) {
            width:100%;
            text-align: center;
            justify-content: center;
            padding:1.5rem;
            }
        }

    }

    .right{
    width:100%;
    margin-bottom: 4rem;

        @media only screen and (min-width: 400px) {       
        text-align: center;
        }

        @media only screen and (min-width: 768px) {        
        width:40%;
        margin-left:10%;
        margin-bottom: 0;
        }

        .gatsby-image-wrapper{

            @media only screen and (min-width: 400px) {        
            max-width: 60vw;
            max-height: 60vw;
            }

            @media only screen and (min-width: 768px) {        
            max-width: 35vw;
            max-height: 35vw;
            }
        }

    }

`

export { Styling }