import styled from 'styled-components'

const Styling = styled.div`
width:100%;
background:${(props) => props.darkLightMode === 'light' || props.darkLightMode === undefined ? 'var(--white)' : 'var(--black)'};
color:${(props) => props.darkLightMode === 'light' || props.darkLightMode === undefined ? 'var(--black)' : 'var(--white)'};
padding:4rem;

@media only screen and (min-width: 600px) {
    padding:10rem;
}


    .main-title{
    text-transform: uppercase;
    font-size:clamp(3rem, 3vw, 9rem);
    width: 100%;
    text-align:center;
    margin-bottom:8rem;
    }

    .deliverables-wrapper{
        
        li{
        display: flex;
        width:100%;
        margin-bottom: 2rem;
        flex-wrap:wrap;

        @media only screen and (min-width: 800px) {
        flex-wrap:nowrap;
        }

            .image-wrapper{
            width: 100%;
            height:auto;
            aspect-ratio: 16/9;
            order:2;
            margin-top: 2rem;
            
                @media only screen and (min-width: 800px) {
                    order:1;
                    width: 30%;
                    height:100%;
                    margin-top: 0;
                }

            }

            .info{
            width:100%;
            border-top:1px solid ${(props) => props.darkLightMode === 'light' || props.darkLightMode === undefined ? 'rgba(0,0,0,.2)' : 'rgba(255,255,255,.2)'};
            padding-top: 4rem;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            align-items: flex-start;
            position: relative;
            order:1;

                @media only screen and (min-width: 800px) {
                order:2;
                width:65%;
                margin-left:2rem;
                }

                .icon{
                display:none;

                    @media only screen and (min-width: 1024px) {
                    width: 15rem;
                    height:100%;
                    display: block;
                    }
                

                    svg{
                    font-size:6rem;
                    margin:0 auto;
                    position: relative;
                    display:block;
                    transform: translateY(-1.75rem);
                    }

                }

                .text-wrapper{
                width: 100%;

                    @media only screen and (min-width: 1024px) {
                    width:calc(100% - 15rem - 4rem);
                    }
                }

                .title{
                text-transform:uppercase;
                font-size:clamp(2rem, 1.4vw, 8rem);
                margin-bottom: 2rem;
                }

                .text{
                font-size:clamp(1.6rem, 0.8vw, 3rem);
                line-height:clamp(1.6rem * 1.5, 0.8vw * 1.5, 3rem * 1.5);
                }

            }

        }

    }


`

export { Styling }