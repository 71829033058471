import styled from 'styled-components'

const Styling = styled.div`
display: flex;
flex-wrap:wrap;
background:${(props) => props.darkLightMode === 'light' || props.darkLightMode === undefined ? 'var(--white)' : 'var(--black)'};
color:${(props) => props.darkLightMode === 'light' || props.darkLightMode === undefined ? 'var(--black)' : 'var(--white)'};
padding:4rem 2rem; 

    @media only screen and (min-width: 350px) {
    padding:4rem; 
    }

    @media only screen and (min-width: 768px) {
    padding:8rem 4rem 6rem 4rem; 
    }

    @media only screen and (min-width: 1200px) {
        padding:12rem 8rem 8rem 8rem; 
    }

    .main_title{
    width: 100%;
    text-align:center;
    /* font-size:clamp(3.5rem, 6vw, 20rem);
    line-height:clamp(3.5rem/1.25, 6vw/1.25, 20rem/1.25); */
    text-transform:uppercase;
    ${({theme}) => theme.tomato('regular')}  
    letter-spacing: -0.06em;
    margin-bottom: 2rem;
    /* padding:0 20%; */
    font-size:clamp(3rem, 3vw, 9rem);
    width: 100%;
    text-transform: uppercase;
    letter-spacing: -0.07em;
    }

    .text{
    max-width:800px;
    margin:0 auto 8rem auto;
    text-align:center;
    line-height: 1.5em;
    }

    ul{
    width: 100%;
    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(1, 1fr);

        @media only screen and (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
        }

        li{
        text-align:center;
        border-bottom: 1px solid rgba(0,0,0,0.2);
        padding:0 0 2rem 0;
        margin-bottom: 2rem;

            @media only screen and (min-width: 768px) {
            border-right: 1px solid var(--grey_mid);
            border-bottom: 0;
            padding:0 10%;
            }

            img{
            width:6rem;
            height:6rem;
            margin-bottom: 2rem;
            filter:invert(${(props) => props.darkLightMode === 'light' || props.darkLightMode === undefined ? '0%' : '100%'});
            }

            &:last-child{
            border-right:0;
            margin-bottom: 0;
            border-bottom: 0;
            padding-bottom: 0;
            }

            strong{
                ${({theme}) => theme.tomato('bold')}  
            }

            p{
            margin-bottom: 2rem;
            line-height: 1.5em;
            }

        }

    }

`

export { Styling }