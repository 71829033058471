import styled from 'styled-components'

const Styling = styled.div`
position: relative;
background:${(props) => props.darkLightMode === 'dark' || props.darkLightMode === undefined ? 'var(--black)' : 'var(--white)'};
color:${(props) => props.darkLightMode === 'dark' || props.darkLightMode === undefined ? 'var(--white)' : 'var(--black)'};
display: inline-flex;
width:100%;
flex-wrap: wrap;

    @media only screen and (min-width: 1024px) {
    margin-top:${(props) => props.removeTopMargin === true ? '0' : '8rem'};
    flex-wrap: nowrap;
    }

    .features-wrapper{
    width: 100%;
    color:${(props) => props.darkLightMode === 'dark' || props.darkLightMode === undefined ? 'var(--white)' : 'var(--black)'};
    padding:4rem 2rem;

        @media only screen and (min-width: 350px) {
        padding:4rem;
        }

        @media only screen and (min-width: 1024px) {
        padding:8rem;
        width: 50vw;
        }

        .title{
        text-transform:uppercase;
        ${({theme}) => theme.tomato('regular')}  
        display: flex;
        align-items: center;
        font-size:3.5rem;
        margin-bottom: 4rem;

            @media only screen and (min-width: 1024px) {
            font-size:clamp(5rem, 2vw, 10rem);
            margin-bottom: 4rem;
            }

            svg{
            width:0.7em;
            height:0.7em;
            margin-left:1rem;
            }

        }

        ul{
        width: 100%;
        display: inline-flex;
        flex-wrap: wrap;
        margin-bottom: 3rem;

            li{
            width: 50%;
            margin-bottom: 1rem;
            display: flex;
            align-items: center;

                &:before{
                width:0.4rem;
                height:0.4rem;
                border-radius:100%;
                background:var(--white);
                content:"";
                margin-right: 1rem;
                }

            }
            
        }

        &.full-width{
        width:100%;

            ul{
            display:grid;
            grid-gap:2rem;
            grid-template-columns:repeat(1, 1fr);

                @media only screen and (min-width: 350px) {
                grid-template-columns:repeat(2, 1fr);
                }

                @media only screen and (min-width: 550px) {
                grid-template-columns:repeat(3, 1fr);
                }

                @media only screen and (min-width: 768px) {
                grid-template-columns:repeat(4, 1fr);
                }

                @media only screen and (min-width: 1024px) {
                grid-gap:4rem;
                grid-template-columns:repeat(5, 1fr);
                }

                li{
                width:auto;
                }

            }

        }
        
        .capsule_button{
            @media only screen and (max-width: 600px) {
            width:100%;
            padding: 1.5rem;
            justify-content: center;
            }
        }

    }

    .video-wrapper{
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    width:100%;

        @media only screen and (min-width: 1024px) {
        width: 50vw;
        }

        svg{
        z-index: 2;
        width:clamp(10rem, 10vw, 20rem);
        height:clamp(10rem, 10vw, 20rem);
        ${({theme}) => theme.easing('0.5')}  

            @media only screen and (max-width: 1024px) {
            position: absolute;
            }

        }

        video{
        width: 100%;
        position: relative;

            @media only screen and (min-width: 1024px) {
            position: absolute;
            }
        }

        &:hover{
            svg{
            transform: scale(1.2);
            }
        }
        
        &.playing svg{
        opacity: 0;
        transform: scale(3);
        }


    }

`

export { Styling }