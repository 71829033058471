import React from 'react'
import { Styling } from './styled.js'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import parse from 'html-react-parser'
import {BsArrowRightShort} from 'react-icons/bs'

export default function WhatYoullLearn({
    deliverables,
    darkLightMode,
    index
}) {
    return (
        <Styling id={`section_${index+1}`} darkLightMode={darkLightMode}>
              <div className="main-title">What You'll Learn</div>
              
              <ul className="deliverables-wrapper">
                  {
                      deliverables.map((deliverable, index) => {
                        const theImage = getImage(deliverable?.image?.localFile);
                        return (
                            <li>
                                <div className="image-wrapper">
                                    <GatsbyImage image={theImage} className="image" alt={'test'} />
                                </div>
                                <div className="info">
                                    <div className="icon">
                                        <BsArrowRightShort />
                                    </div>
                                    <div className="text-wrapper">
                                        <div className="title">{deliverable.title || deliverable.heading}</div>
                                        <div className="text">{parse(deliverable.text)}</div>
                                    </div>
                                </div>
                            </li>
                          )
                      })
                  }
              </ul>
        </Styling>
    )
}
